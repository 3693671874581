import React from "react";
import { Link } from "gatsby";
import ChevronRightIcon from "../../images/svg/icons/chevron-right-icon.svg";
import { useIntl } from "react-intl";
import { defaultLanguage } from "../../i18n";
import parse from "html-react-parser";

const CustomPostItem = ({ title, excerpt, featuredImage, slug }) => {
  // Strip HTML tags
  const rawExcerpt = excerpt.replace(/(<([^>]+)>)/gi, "");

  const intl = useIntl();
  const locale =
    defaultLanguage === intl.locale
      ? `/${process.env.GATSBY_DEFAULT_LANG}/${process.env.GATSBY_CUSTOM_POST_ONE_SLUG}/`
      : `/${intl.locale}/${process.env.GATSBY_CUSTOM_POST_ONE_SLUG}`;

  let thumbnail;
  let alt;
  if (featuredImage !== null && featuredImage.node !== null) {
    alt = featuredImage?.node.slug;
    let filteredSizes = featuredImage?.node.mediaDetails.filteredSizes;
    if (filteredSizes.length > 0) {
      let getThumbnail = filteredSizes.filter(
        (img) => img.name === "td_265x198"
      );
      thumbnail = getThumbnail[0]?.sourceUrl;
    } else {
      thumbnail = featuredImage?.node.mediaItemUrl;
    }
  } else {
    thumbnail = "thumb";
    alt = "alt";
  }

  return (
    <li className="custom-posts-list__list-item">
      <Link
        className="custom-posts-list__link"
        to={
          process.env.GATSBY_MULTILINGUAL === "true"
            ? `${locale}${slug}/`
            : `/${slug}/`
        }
      >
        <div className="custom-posts-list__container">
          <div className="custom-posts-list__image">
            <img loading="lazy" src={thumbnail} alt={alt} />
          </div>
          <div className="custom-posts-list__text">
            <div className="custom-posts-list__title">{title}</div>
            <div className="custom-posts-list__excerpt">
            {parse(rawExcerpt)}</div>
          </div>
          <div className="custom-posts-list__icon">
            <ChevronRightIcon />
          </div>
        </div>
      </Link>
    </li>
  );
};

const CustomPostsList = ({ postData }) => {
  return (
    <div className="custom-posts-list">
      <ul className="custom-posts-list__list">
        {postData.map((post) => (
          <CustomPostItem
            key={post.node.id}
            title={post.node.title}
            excerpt={post.node.excerpt}
            featuredImage={post.node.featuredImage}
            slug={post.node.slug}
          />
        ))}
      </ul>
    </div>
  );
};

export default CustomPostsList;
